<template>
  <div>
    <v-card v-if="!hideButton && !button" elevation="6" hover class="reader-button" :class="{'active': state.reader.open && !disabled, 'inline': inline}" @click="activateReader()" v-click-outside="handleOutsideClick">
      <v-layout justify-center>
        <v-icon v-if="state.reader.error && !disabled" class="mr-2" color="error">mdi-alert-circle-outline</v-icon>
        <v-icon v-else-if="connecting && !disabled" class="mr-2">mdi-clock-outline</v-icon>
        <v-icon v-else class="mr-2">mdi-nfc-variant</v-icon>
        <v-layout align-center>
          <div v-if="state.reader.error && !disabled" class="flex-column my-2" style="height: 100%;">
            <div class="title grey--text text--darken-2">{{$t(errorText.title)}}</div>
            <div class="subheading grey--text text--darken-2" v-if="$te(errorText.description)">{{$t(errorText.description)}}</div>
            <v-spacer/>
            <v-btn @click="retry()" small class="ma-0 flex-none px-0" text color="primary">{{$t('actions.retry')}}</v-btn>
          </div>
          <v-layout v-else column class="grey--text text--darken-2">
            <span v-if="!connecting || (connecting && disabled)" class="subheading" :class="{'title': state.reader.open && !disabled}">{{ $t('reader.read_identifier') }}</span>
            <span v-if="state.reader.open && !connecting && !disabled" class="subheading">{{ $t('reader.bring_wristband_closer') }}</span>
            <span v-if="state.reader.open && connecting && !disabled" class="title">{{$t('reader.attempting_connection')}}</span>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-card>
    <v-btn v-else fab tile depressed color="white" @click="$emit('buttonClick'); activateReader()">
      <v-icon v-if="state.reader.error && !disabled" color="error" large>mdi-alert-circle-outline</v-icon>
      <v-icon v-else-if="connecting && !disabled" large color="grey darken-2">mdi-clock-outline</v-icon>
      <v-icon v-else large color="grey darken-2">mdi-nfc-variant</v-icon>
    </v-btn>
    <v-fade-transition v-if="!disabled && !inline" mode="out-in">
      <div class="overlay" v-if="state.reader.open" @click="$store.commit('state/setReaderState', false)">
        <slot name="content"></slot>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import peripheralConnectionMixins from '@/mixins/PeripheralSocket'
export default {
  // EMITTED EVENTS: data, click
  props: {
    hideButton: Boolean, // Hides default modal
    button: Boolean, // Hides default modal and shows activator button
    inline: Boolean,
    action: String
  },
  mixins: [
    peripheralConnectionMixins
  ],
  methods: {
    goToGuest (data) {
      if (data.success) {
        this.$emit('data', data)
        this.$store.commit('state/setReaderState', false)
      } else if (data.error_code === 'TIMEOUT') {
        this.$store.commit('state/setReaderState', false)
      } else {
        this.$store.commit('state/setReaderError', data.error_code)
      }
    },
    activateReader () {
      if (!this.state.reader.open) {
        this.$store.commit('state/setReaderState', this.action)
      }
    },
    displayError (error) {
      this.$store.commit('state/setReaderError', error.code)
    },
    handleOutsideClick () {
      if (!this.disabled) {
        this.$store.commit('state/setReaderState', null)
      }
    } 
  },
  created () {
    if (this.state.reader.open === 'READ_TAG_ID') {
      this.sendAction(this.state.reader.open, {}, this.goToGuest, (error) => { this.displayError(error) })
    }
  },
  computed: {
    ...mapState(['state']),
    errorText () {
      if (!this.state.reader.error) {
        return {}
      } else if (this.$te(`reader.error.${this.state.reader.error}.title`)) {
        return {
          title: `reader.error.${this.state.reader.error}.title`,
          description: `reader.error.${this.state.reader.error}.description`
        }
      } else {
        return {
          title: `reader.error.FALLBACK.title`,
          description: `reader.error.FALLBACK.description`
        }
      }
    },
    disabled () {
      return this.state.reader.open !== this.action
    },
    connecting () {
      return !this.state.reader.connected && this.state.reader.showConnecting
    }
  },
  beforeDestroy () {
    this.cancelAction()
  },
  watch: {
    'state.reader.open' (val) {
      if (this.state.reader.open !== 'READ_TAG_ID' && val) {
        this.sendAction(this.state.reader.open, {}, this.goToGuest, (error) => { this.displayError(error) })
      } else if (!val) {
        this.cancelAction()
      }
    }
  }
}
</script>
<style lang="scss">
.overlay {
  background-color: rgba(0, 0, 0, 0.30);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.reader-button {
  z-index: 15;
  &:not(.inline) {
    bottom: 15px;
    right: 15px;
    padding: 20px;
    position: fixed;
  }
  &.inline {
    padding: 10px;
  }
  .v-icon {
    font-size: 50px;
    transition: all 0.2s ease-in-out;
  }
  &.active {
    .v-icon {
      font-size: 70px;
    }
  }
}
</style>
